export function hex2rgb (hex, opacity) {
  hex = (hex + '').trim()

  let rgb = null
  const match = hex.match(/^#?(([0-9a-zA-Z]{3}){1,3})$/)

  if (!match) {
    return null
  }

  rgb = {}

  hex = match[1]

  if (hex.length === 6) {
    rgb.r = parseInt(hex.substring(0, 2), 16)
    rgb.g = parseInt(hex.substring(2, 4), 16)
    rgb.b = parseInt(hex.substring(4, 6), 16)
  } else if (hex.length === 3) {
    rgb.r = parseInt(hex.substring(0, 1) + hex.substring(0, 1), 16)
    rgb.g = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16)
    rgb.b = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16)
  }

  rgb.css = 'rgb' + (opacity ? 'a' : '') + '('
  rgb.css += rgb.r + ',' + rgb.g + ',' + rgb.b
  rgb.css += (opacity ? ',' + opacity : '') + ')'

  return rgb
}

export function findInNestedByName (array, name) {
  if (typeof array !== 'undefined') {
    for (let i = 0; i < array.length; i++) {
      if (array[i].name === name) return [{ ...array[i] }]
      const a = this.findInNestedByName(array[i].children, name)
      if (a != null) {
        a.unshift({ ...array[i] })
        return [...a]
      }
    }
  }
  return null
}

export function pushToPosition (original, position, element) {
  let newArray = original.slice(0, position)
  newArray.push(element)

  newArray = newArray.concat(original.slice(position))
  return newArray
}

export function hasOwnNestedProperty (obj, propertyPath) {
  if (!propertyPath) return false

  const properties = propertyPath.split('.')

  for (let i = 0; i < properties.length; i++) {
    const prop = properties[i]

    if (!obj || !obj[prop]) return false
    obj = obj[prop]
  }

  return true
}

export function roundToTwo (num) {
  return +(Math.round(num + 'e+2') + 'e-2')
}

export default {
  hex2rgb,
  findInNestedByName,
  pushToPosition,
  hasOwnNestedProperty,
  roundToTwo,
}
